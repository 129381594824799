export const colors: [string, string][] = [
  ['#2ABBFB', '#0095D6'],
  ['#B9A0FF', '#6F3CFF'],
  ['#FF7050', '#E53C18'],
  ['#FFC738', '#FF8A00'],
  ['#FFAF50', '#FF6B00'],
  ['#FF7A2E', '#FF3D00'],
  ['#A35B33', '#6A3C20'],
  ['#3ABD47', '#0D8719'],
  ['#FF97C9', '#D61471'],
  ['#448BF4', '#273FBA'],
  ['#BB5EF1', '#8122B8'],
  ['#28B299', '#0D7764'],
  ['#46597D', '#323552'],
  ['#629B29', '#3A730C'],
  ['#CD9A4C', '#BA713C'],
  ['#175EA0', '#2A27BA'],
  ['#D95169', '#B82273'],
  ['#2DAE70', '#13695A'],
];
