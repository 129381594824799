import {http} from '@utils';

export interface LotteryGameCardItem {
  categoryId: number;
  // 				"packageId":5,
  // 				"sort":1,
  // 				"twoCategoryId":22
  // 游戏图片
  gamePic: string;
  otherUrl: string;
  otherParams: string;
  // 游戏价格
  gamePrice: string;
  // 具体游戏类型 Lottery类型分dice和color，其他楼层类型一样
  gameType: string; // "Dice"
  // 游戏url，对于dice和color和scratch，需要自行配置url进行匹配，
  gameUrl: string; // "configId=16"
  // 游戏名称
  name: string; //"Dice 1 minute"
  // 游戏厂商
  provider: string; // "SKY GAME"
  // 厂商独立游戏编号，比如SA厂商使用的defaultTable
  tripartiteUniqueness: string;
  // 游戏id，Slotegrator厂商使用这个拿到链接
  id: number;
  // 游戏厂商 判断跳转的使用这个
  source: string; // "SKY GAME"
  grandPrize: number;
  maxBetting: number;
  maxBonus: number;
  playersNumber: number;
  picPlaceholder?: number;
  lottoInfo?: {
    lotteryId: string;
    wonAmount: `${number}`;
    gameType: 'quick' | undefined;
  };
  otherInfo?: {
    roundNo: string;
    stopTime: `${number}`;
  };
}
export interface LotteryGameFloorItem {
  // 楼层icon图片
  categoryPic: string;
  gamesList: LotteryGameCardItem[];
  // 楼层标题
  name: string;
  // 楼层唯一key
  uniqueKey: string; // hotGames lotteryV2 indianCasino liveCasinoV2 Slots New Games
  id: number;
  currentTimestamp?: number;
}

export const getLotteryGames = () => {
  return http.post<
    {
      position: 7;
    },
    LotteryGameFloorItem[]
  >('app/homeGamesCategory/getAllListBySortV2', {
    position: 7,
  });
};

export interface MatkaListItem {
  backImg: string;
  closeDraw: string;
  closeResultNum: string;
  openResultNum: string;
  fullStatus: number;
  halfStatus: number;
  id: number;
  isClose: number;
  lotteryName: string;
  minPrice: number;
  openDraw: string;
  wonAmount: number;
}

export const getMatkaList = () => {
  return http.post<null, MatkaListItem[]>('app/matka/lottery/home/listV2');
};
