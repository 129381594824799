import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {goBack} from '@/utils';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ScrollView} from 'react-native';
import Wallet from '@/common-pages/wallet';
import Spin from '@/components/basic/spin';
import {
  getLotteryGames,
  LotteryGameFloorItem,
  getMatkaList,
  MatkaListItem,
} from './lottery.service';
import LotteryFloorGames from './components/lottery-floor-games';
import {NoMoreData} from '@basicComponents/default-page';

const openSata = false;

const Lottery = () => {
  const {i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [lotteryGames, setLotteryGames] = useState<LotteryGameFloorItem[]>();

  const init = async () => {
    setLoading(true);
    let matkeList: MatkaListItem[];
    if (openSata) {
      matkeList = await getMatkaList();
    }
    getLotteryGames()
      .then(async games => {
        const list = await games.map(game => {
          if (game.uniqueKey === 'indianLottery') {
            game.gamesList = game.gamesList.sort(
              (a, b) =>
                +(b?.otherParams || '').split('stopTime=')[1] -
                +(a?.otherParams || '').split('stopTime=')[1],
            );
          }
          if (openSata && game.uniqueKey === 'matkaV3') {
            const ele = (game.gamesList && game.gamesList[0]) || {};
            const {gameUrl} = ele;
            game.gamesList = matkeList?.map((item: MatkaListItem) => {
              return {
                categoryId: 28,
                gamePic: item.backImg,
                id: item.id,
                name: item.lotteryName,
                gamePrice: '' + item.minPrice,
                otherParams: `openResultNum=${
                  item.openResultNum || ''
                }&closeResultNum=${item.closeResultNum || ''}&openDraw=${
                  item.openDraw || ''
                }&closeDraw=${item.closeDraw || ''}&isClose=${
                  item.isClose || ''
                }&fullStatus=${item.fullStatus || ''}&halfStatus=${
                  item.halfStatus || ''
                }&wonAmount=${item.wonAmount || ''}`,
                gameUrl: gameUrl || '',
                // 暂时无确定值
                gameType: 'Others',
                provider: 'SKY GAME',
                source: 'SKY GAME',
                otherUrl: '',
                packageId: 5,
                picPlaceholder: 1,
                playersNumber: 1,
                twoCategoryId: 28,
                sort: 3,
                tripartiteUniqueness: '',
                grandPrize: 0,
                maxBetting: 0,
                maxBonus: 0,
                item,
              };
            });
          }
          return game;
        });
        setLotteryGames(
          openSata ? list : list.filter(v => v.uniqueKey !== 'matkaV3'),
        );
      })
      .catch(e => {
        const {log} = console;
        log('error', e);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <View style={[theme.fill.fill, theme.flex.col, theme.background.lightGrey]}>
      <DetailNavTitle
        title={i18n.t('label.lottery')}
        hideAmount
        rightNode={<Wallet theme="dark" />}
        onBack={goBack}
        hideServer
      />
      <Spin loading={loading} style={[theme.flex.flex1, theme.flex.col]}>
        <View style={[theme.flex.flex1, theme.flex.basis0]}>
          <ScrollView>
            {lotteryGames?.map((floor, index) => {
              return floor.gamesList ? (
                <LotteryFloorGames
                  serverTime={floor.currentTimestamp}
                  type={floor.uniqueKey}
                  key={index}
                  title={floor.name}
                  icon={floor.categoryPic}
                  list={floor.gamesList || []}
                />
              ) : null;
            })}
            <NoMoreData />
          </ScrollView>
        </View>
      </Spin>
    </View>
  );
};

export default Lottery;
